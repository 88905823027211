import { Typography } from '@mui/material'
import React from 'react'

export default function LogoComponent({textColor}) {
  return (
    
 <a href="/">
 <Typography align="center" color={textColor?textColor:"#0f0e58"} variant="h4" sx={{ fontWeight:"bolder" }}>
   Student
   <span
     style={{
       color: "green",
       height: "8px",
       width: "8px",
       display: "inline-block",
       margin:"1px"
     }}
   >.</span>
   et
 </Typography>
</a>
  )
}

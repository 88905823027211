import { Box, Stack } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import LogoutButton from "../Verification/LogoutButton";

export default function AppLayout({ children }) {
  const { user,status } = useContext(AuthContext);

  return (
    <>
      <Stack
        direction="column"
        style={{
          height: "97vh",
          width: "full",
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      
      >
        {user.isAuth && !status.pageLoading && (
          <Box >
            <LogoutButton />
          </Box>
        )}

        {children}
      </Stack>
    </>
  );
}

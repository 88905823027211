import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { theme } from './constants/Theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './contexts/AuthContext';
import './index.css';

// Render the app
ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthContextProvider>

  <ThemeProvider theme={theme}>
    <React.StrictMode>
        <App />
    </React.StrictMode>

  </ThemeProvider>
  </AuthContextProvider>

);

reportWebVitals();

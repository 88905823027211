import React, { useState } from "react";
import { Outlet, useOutlet } from "react-router-dom";
import "../index.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeSection from "../components/Sections/HomeSection";
import AboutSection from "../components/Sections/AboutSection";
import ServiceSection from "../components/Sections/ServiceSection";
import ContactSection from "../components/Sections/ContactSection";
export default function Home() {
  const outlet = useOutlet();
  const [isLoading, setIsLoading] = useState(false);
  const onClick = () => {
    setIsLoading(true);
    const timeInterval = setInterval(() => {
      setIsLoading(false);
    }, 5000);

    clearInterval(timeInterval);
  };

  return (
    <>
      {outlet ? (
        <Outlet />
      ) : (
        // <AppLayout>
        <>
          <Header />

          <main class="w-full">
            <HomeSection onClick={onClick} isLoading={isLoading}/>

            <AboutSection/>

           <ServiceSection/>
            <section class="  py-16 sm:py-20 bg-gray-100">
              <div class="container mx-auto px-4">
                <h3 class="text-3xl font-semibold text-center mb-12 sm:mb-16 text-brand">
                  How It Works
                </h3>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-12">
                  <div class="bg-white p-6 sm:p-8 rounded-2xl text-center">
                    <div class="bg-brand text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-6 text-2xl font-bold">
                      1
                    </div>
                    <h4 class="text-xl font-semibold mb-4 text-brand">
                      Sign Up
                    </h4>
                    <p class="text-gray-600">
                      Create an account using your university email address and
                      join our community.
                    </p>
                  </div>
                  <div class="bg-white p-6 sm:p-8 rounded-2xl text-center">
                    <div class="bg-brand text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-6 text-2xl font-bold">
                      2
                    </div>
                    <h4 class="text-xl font-semibold mb-4 text-brand">
                      Verify
                    </h4>
                    <p class="text-gray-600">
                      Upload your student ID for a quick and secure verification
                      process.
                    </p>
                  </div>
                  <div class="bg-white p-6 sm:p-8 rounded-2xl text-center">
                    <div class="bg-brand text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-6 text-2xl font-bold">
                      3
                    </div>
                    <h4 class="text-xl font-semibold mb-4 text-brand">
                      Save & Thrive
                    </h4>
                    <p class="text-gray-600">
                      Access exclusive discounts and opportunities to enhance
                      your student life.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="  py-16 sm:py-20 bg-brand text-white">
              <div class="container mx-auto px-4 text-center">
                <h3 class="text-3xl font-semibold mb-10 sm:mb-12">
                  Join Our Thriving Community
                </h3>
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-8 sm:gap-12">
                  <div class="bg-white bg-opacity-10 rounded-lg p-6 transform hover:scale-105 transition duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-16 mx-auto mb-4 text-yellow-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                    <p class="text-4xl sm:text-5xl font-bold mb-2">10,000+</p>
                    <p class="text-lg sm:text-xl">Verified Students</p>
                  </div>
                  <div class="bg-white bg-opacity-10 rounded-lg p-6 transform hover:scale-105 transition duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-16 mx-auto mb-4 text-green-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                    <p class="text-4xl sm:text-5xl font-bold mb-2">500+</p>
                    <p class="text-lg sm:text-xl">Partner Businesses</p>
                  </div>
                  <div class="bg-white bg-opacity-10 rounded-lg p-6 transform hover:scale-105 transition duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-16 mx-auto mb-4 text-blue-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 14l9-5-9-5-9 5 9 5z" />
                      <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                      />
                    </svg>
                    <p class="text-4xl sm:text-5xl font-bold mb-2">50+</p>
                    <p class="text-lg sm:text-xl">Universities Covered</p>
                  </div>
                </div>
              </div>
            </section>

           <ContactSection/>

            <section class="  py-16 sm:py-20 bg-white">
              <div class="container mx-auto px-4">
                <h3 class="text-3xl font-semibold text-center mb-12 sm:mb-16 text-brand">
                  Featured Partners
                </h3>
                <div class="flex flex-row mx-20 overflow-hidden flex-nowrap auto-scroll justify-center items-center space-x-20">
                    <img
                      src="assets/hahucloud-logo.svg"
                      alt="HahuCloud Logo"
                      class=" object-include h-24 max-w-32 "
                    />
                  
                    <img
                      src="assets/ichereta-logo.svg"
                      alt="HahuCloud Logo"
                      class=" object-include h-24 max-w-32 "
                    />
                
                  
                 
                </div>
              </div>
            </section>
          </main>

          <Footer />
        </>
        // </AppLayout>
      )}
    </>
  );
}

const redirectUser = async ({ user }) => {
  let path = null;
  if(user.isAuth){
  if (user.phone_verified_at === null) {
    path = "verify-phone";
  } else if (!user.is_info_added) {
    path = "verify-id";
  }  else if (!user.is_id_uploaded) {
    path = "upload-id";
  } else if (user.status === 0) {
    path = "under-review";
  }else if(user.status === 1 && user.student_et_id!==null){
    path="profile"
  }else{
    path="/login"
  }

}
  return path;
};

export { redirectUser };

const inputFontSize = {
  xs: 12,
  sm: 18,
};

const inputHeight = {
  xs: "48px",
  sm: "60px",
};



const iconSize = { xs: 15, sm: 20 };

const baseUrl="https://api.student.et/api/";


export { inputFontSize,iconSize ,baseUrl,inputHeight};

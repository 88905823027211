import React from 'react'

export default function ServiceSection() {
  return (
    <section id="service" class="py-16 w-full sm:py-20 bg-white">
              <div class="container mx-auto px-4">
                <h3 class="text-3xl font-semibold text-center mb-12 sm:mb-16 text-brand">
                  Our Services
                </h3>
                <div class="grid sm:grid-cols-2 gap-8 sm:gap-16">
                  <div class="bg-gray-50 p-8 sm:p-10 rounded-2xl">
                    <h4 class="text-2xl font-semibold mb-6 text-brand">
                      For Students
                    </h4>
                    <p class="mb-8 text-gray-600 leading-relaxed">
                      Get verified and unlock a world of exclusive discounts
                      from various businesses. Enhance your student experience
                      with amazing deals and connect with a vibrant community of
                      peers!
                    </p>
                    <a
                      href="/register"
                      class="bg-brand text-white px-6 py-2 rounded-full hover:bg-opacity-90 transition duration-300 inline-block"
                    >
                      Get Verified
                    </a>
                  </div>
                  <div class="bg-gray-50 p-8 sm:p-10 rounded-2xl">
                    <h4 class="text-2xl font-semibold mb-6 text-brand">
                      For Businesses
                    </h4>
                    <p class="mb-8 text-gray-600 leading-relaxed">
                      Partner with us to offer student discounts, grow your
                      customer base, and contribute to the educational
                      community. Reach a dynamic and engaged audience of future
                      professionals!
                    </p>
                    <a
                      href="#contact"
                      class="bg-brand text-white px-6 py-2 rounded-full hover:bg-opacity-90 transition duration-300 inline-block"
                    >
                      Partner With Us
                    </a>
                  </div>
                </div>
              </div>
            </section>

  )
}

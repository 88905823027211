import React from "react";
import AppLayout from "../components/layouts/AppLayout";
import { Typography } from "@mui/material";

export default function NotFound() {
  return (
    <AppLayout>
      <Typography variant="h3">404</Typography>
    </AppLayout>
  );
}

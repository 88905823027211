import React from 'react'
import LogoComponent from './auth/LogoComponent'

export default function Footer() {
  return (
   <>
    <footer class="bg-gray-900 text-white py-12 w-full sm:py-16">
            <div class="container mx-auto px-4">
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 sm:gap-12">
                <div>
               
               <div class="flex justify-start">
               <LogoComponent textColor={'text-gray-400 '}/>

               </div>
                  <p class="text-gray-400 leading-relaxed">
                    Empowering students with exclusive discounts and
                    opportunities for a brighter future.
                  </p>
                </div>
                <div>
                  <h4 class="text-xl font-semibold mb-4 sm:mb-6">
                    Quick Links
                  </h4>
                  <ul class="space-y-2">
                    <li>
                      <a
                        href="/"
                        class="text-gray-400 hover:text-white transition duration-300"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        class="text-gray-400 hover:text-white transition duration-300"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/service"
                        class="text-gray-400 hover:text-white transition duration-300"
                      >
                        Services
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        class="text-gray-400 hover:text-white transition duration-300"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 class="text-xl font-semibold mb-4 sm:mb-6">Contact Us</h4>
                  <p class="text-gray-400 mb-2">Email: info@student.et</p>
                  <p class="text-gray-400">Phone: +251 123 456 789</p>
                </div>
                <div>
                  <h4 class="text-xl font-semibold mb-4 sm:mb-6">Follow Us</h4>
                  <div class="flex space-x-4">
                    <a
                      href="/"
                      class="text-gray-400 hover:text-white transition duration-300"
                    >
                      Facebook
                    </a>
                    <a
                      href="/"
                      class="text-gray-400 hover:text-white transition duration-300"
                    >
                      Twitter
                    </a>
                    <a
                      href="/"
                      class="text-gray-400 hover:text-white transition duration-300"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
              <div class="mt-8 pt-8 border-t border-gray-700 text-center">
                <p>&copy; 2024 Student.et. All rights reserved.</p>
              </div>
            </div>
          </footer>
          </>
  )
}

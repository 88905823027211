import React, {  useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import VerifyOTPForm from "../../components/Verification/VerifyOTPForm";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "../../components/auth/AuthHeader";

export default function VerifyOTP() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [status, setStatus] = useState({
    loading: false,
    verifying: false,
    error: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get("phone");

  const onSubmit = async (data) => {
    data.phone = phone;

    const url = baseUrl + "student/verify-otp";
    setStatus({ ...status, verifying: true });

    try {
      const response = await axios.post(url, data);

      if (response.data.status === true) {
        navigate(`/change-password?phone=${data.phone}`);
        setStatus({ ...status, verifying: false, error: null });
      }
    } catch (error) {
      const msg = error.response?.data.message;
      console.log(msg);
      if (msg) {
        setError("otp", {
          type: "custom",
          message: msg,
        });
      }

      setStatus({
        ...status,
        verifying: false,
      });
    }
  };

  const onResend = async () => {
    const url = baseUrl + "student/send-otp";
    setStatus({ ...status, resending: true });

    try {
      const loggedInToken = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${loggedInToken}`,
        },
      };
      const response = await axios.post(url, config);
      if (response.data.status === true) {
        console.log(response);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
    setStatus({ ...status, resending: false });
  };

  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
            <AuthHeader />

            <Typography align="center" variant="subtitle1">
              Verify Your OTP
            </Typography>
            <VerifyOTPForm
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              status={status}
              onResend={onResend}
              formstate={{ errors }}
            />
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}

import { CircularProgress } from '@mui/material'
import React from 'react'
import AppLayout from './layouts/AppLayout'

export default function Loading() {
  return (
    <>
    <AppLayout>
    <CircularProgress thickness={0.5}  size={60}/>
    </AppLayout>

    </>
  )
}

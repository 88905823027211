import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  CircularProgress
} from "@mui/material";
import { ArrowRightAlt, CheckCircleOutline, CircleOutlined } from "@mui/icons-material";

export default function VerifyIDForm({handleSubmit,onNext,type,setType,status}) {


  return (
   
        <form onSubmit={handleSubmit(onNext)}>
          <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box width={{ xs: "92%", sm: "100%" }} bgcolor="white" color="#1c2145" borderRadius={2}>
          <Box width={{ xs: "90%", sm: "80%" }} mx={"auto"} py={{xs:4,sm:8}} px={{xs:2,sm:2}}>
              <Stack spacing={4}>
                <Typography
                  align="center"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  Verify Your ID
                </Typography>

                <Typography align="center" variant="subtitle1">
                  Choose Verification Method
                </Typography>

                <FormControl fullWidth >
                  <Stack direction={'row'} spacing={2} >
                    <Box onClick={()=>setType(1)}  height={112} width={1/2} bgcolor={'red'} sx={{backgroundColor:type===1?"#1c2145":"#f5f0f0",color:type===1?"#ffffff":"#1c2145",cursor:"pointer"}} borderRadius={3} >
                      <Stack direction={'column'} spacing={2} p={2} alignItems={'space-between'}>
                        <Box > {type===1?<CheckCircleOutline fontSize="20px"/>:<CircleOutlined fontSize="20px"/>} </Box>
                        <Box>  <Typography> Student <br/> ID Card</Typography></Box>
                      </Stack>
                    </Box>

                    <Box onClick={()=>setType(2)}  height={112} width={1/2} bgcolor={'red'} sx={{backgroundColor:type===2?"#1c2145":"#f5f0f0",color:type===2?"#ffffff":"#1c2145",cursor:"pointer"}} borderRadius={3}>
                    <Stack direction={'column'} spacing={2} p={2} alignItems={'space-between'}>
                        <Box> {type===2?<CheckCircleOutline fontSize="20px"/>:<CircleOutlined fontSize="20px"/>}</Box>
                       <Box> <Typography> School <br/> Email Address</Typography></Box>
                      </Stack>
        
                    </Box>

                  </Stack>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    backgroundColor: "#0f0e58",
                    ":hover": { backgroundColor: "#0f0e78" },
                  }}
                  disabled={status.loading }
                >
                  {status.loading ? (
                    <CircularProgress sx={{color:"#0f0e58"}} size={25} />
                  ) : (
                 <>Next <ArrowRightAlt  /></>
                )}
                
                  
                </Button>
            
              </Stack>
            </Box>
            </Box>
          </Box>
        </form>
  
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  TextField,
  Link,
} from "@mui/material";
import { KeyboardReturnOutlined, Password } from "@mui/icons-material";
import { inputFontSize, inputHeight } from "../../constants/Constant";

export default function VerifyOTPForm({
  register,
  handleSubmit,
  onVerifyOTP,
  status,
  onResendOTP,
  formstate: { errors },
  setStep,
}) {


  const handleStepChange=()=>{
    setStep(1)
  }
  const [timeLeft, setTimeLeft] = useState(10);
  useEffect(() => {
    let timer;

    timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === -1) {
          clearInterval(timer);
          return -1;
        } else {
          return prevTimeLeft - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [status.sending]);
  const handleResendOTP = () => {
    onResendOTP();
    if (!status.sending) {
      setTimeLeft(10);
    }
  };
  let resendContent = null;

  if (status.sending) {
    resendContent = <Typography> Resending...</Typography>;
  } else if (timeLeft >= 0) {
    const time = timeLeft < 10 ? `0${timeLeft}` : timeLeft;
    resendContent = <Typography color={"red"}> 00:{time} </Typography>;
  } else {
    resendContent = (
      <Link
        style={{
          textDecoration: "none",
          color: "#0f0e58",
          pointerEvents: status.loading ? "none" : "auto",
          fontFamily: "Palanquin",
        }}
        onClick={handleResendOTP}
      >
        Resend OTP
      </Link>
    );
  }

  return (
    <form onSubmit={handleSubmit(onVerifyOTP)}>
      <Typography align="center" variant="subtitle1">
        Verify Your OTP
      </Typography>
      <Box width={{ display: "flex", justifyContent: "center" }}>
      <Stack
            width={{ xs: "92%", sm: "100%" }}
            bgcolor="white"
            color="#0f0e58"
            borderRadius={2}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              <FormControl fullWidth>
                <TextField
                  placeholder="OTP"
                  {...(errors.otp ? { error: true } : {})}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                  }}
                  {...register("otp", { required: "OTP Required" })}
                  helperText={errors.otp ? errors.otp.message : null}
                  type="text"
                  sx={{ fontSize: inputFontSize, height: inputHeight }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#12c63f",
                  ":hover": { backgroundColor: "#12c658" },
                }}
                disabled={status.sending || status.loading}
              >
                {status.loading ? (
                  <CircularProgress sx={{ color: "#0f0e58" }} size={25} />
                ) : (
                  "Verify"
                )}
              </Button>

              {resendContent}
            </Stack>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Link
             onClick={handleStepChange}
              style={{
                textDecoration: "none",
                pointerEvents: status.loading ? "none" : "auto",
                color: "#0f0e58",
                fontFamily: "Palanquin",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor:"pointer",
                marginBottom: "5px",

              }}
            >
              <KeyboardReturnOutlined />
              Return
            </Link>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

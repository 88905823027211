import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import LogoComponent from "./auth/LogoComponent";

export default function Header() {
  const { user, status } = useContext(AuthContext);
  const [closed, setClosed] = useState(true);

  const toggleBurgerMenu = () => {
    document.getElementById("mobileMenu").classList.toggle("hidden");
    setClosed(!closed)
  };
  return (
    <>
      <header class="bg-white  w-full sticky top-0 z-50">
        <div class="container mx-auto px-4 py-4">
          <div class="flex justify-between items-center">
           
           <LogoComponent/>
            <nav class="hidden sm:block">
              <ul class="flex space-x-8">
                <li>
                  <a
                    href="#home"
                    class="text-gray-600 hover:text-brand transition duration-300"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    class="text-gray-600 hover:text-brand transition duration-300"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#service"
                    class="text-gray-600 hover:text-brand transition duration-300"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    class="text-gray-600 hover:text-brand transition duration-300"
                  >
                    Contact
                  </a>
                </li>
                {user.isAuth && !status.pageLoading && (
                  <li>
                    <a
                      href="/"
                      class="block py-2 text-gray-600 hover:text-brand transition duration-300"
                    >
                      logout
                    </a>
                  </li>
                )}
              </ul>
            </nav>
            <button
              id="menuToggle"
              onClick={toggleBurgerMenu}
              class="sm:hidden text-brand"
            >
              {closed ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path fill="currentColor" d="m16.192 6.344l-4.243 4.242l-4.242-4.242l-1.414 1.414L10.535 12l-4.242 4.242l1.414 1.414l4.242-4.242l4.243 4.242l1.414-1.414L13.364 12l4.242-4.242z"/>
            </svg>
              )}
            </button>
          </div>
          <nav id="mobileMenu" class="hidden sm:hidden mt-4 ">
            <ul class="flex flex-col space-y-1 ">
              <li>
                <a
                  href="#home"
                  class="block py-2 text-sm text-gray-600 hover:text-brand transition duration-300"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  class="block py-2 text-sm text-gray-600 hover:text-brand transition duration-300"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#service"
                  class="block py-2 text-sm text-gray-600 hover:text-brand transition duration-300"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  class="block py-2 text-sm text-gray-600 hover:text-brand transition duration-300"
                >
                  Contact
                </a>
              </li>
              {user.isAuth && !status.pageLoading && (
                <li>
                  <a
                    href="/"
                    class="block py-2 text-sm text-gray-600 hover:text-brand transition duration-300"
                  >
                    logout
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}

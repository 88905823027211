import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

const PrivateRoute = () => {
    let {user}=useContext(AuthContext)
    return(
        user.isAuth ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoute
import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import AuthHeader from "../../components/auth/AuthHeader";
import ProfileForm from "../../components/auth/ProfileForm";
import { Box, Stack } from "@mui/material";

export default function Profile() {
  return (
    <>
     <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
            <AuthHeader />

            <ProfileForm />
          </Stack>
        </Box>
      </AppLayout>
      </>
   
  );
}

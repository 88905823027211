import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react'
import { baseUrl } from '../constants/Constant';

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({ isAuth: false });
    const [token, setToken] = useState("");
    const [status, setStatus] = useState({ error: null, pageLoading: true });

    
    useEffect(() => {
        const authenticateUser = async () => {
           const  loggedInToken=localStorage.getItem('token');

            const config = {
                headers: {
                    'Authorization': `Bearer ${loggedInToken}`
                }
            };
            if (loggedInToken) {
                setToken(loggedInToken);

                const url = baseUrl + 'student/info';

                try {
                    const response = await axios.get(url, config);

                    if (response.data.status === true) {
                        const userData = response.data.userInfo;
                        setUser({ ...userData, isAuth: true }); // Set isAuth to true
                    } else {
                        setUser(null);
                    }
                } catch (err) {
                    setStatus(prevStatus => ({ ...prevStatus, error: err }));
                }finally{
                    setStatus(prevStatus => ({ ...prevStatus, pageLoading: false }));

                }
            }else{
                setStatus(prevStatus => ({ ...prevStatus, pageLoading: false }));

            }

        };

        
        authenticateUser();
    },[user.isAuth]);

    const login = ({ userInfo, token }) => {
        setUser({ ...userInfo, isAuth: true }); // Set isAuth to true
        setToken(token);
        localStorage.setItem('token', token);
    };

    const logout = () => {
        setUser({ isAuth: false }); 
        setToken(null);
        localStorage.removeItem('token');

    };

    const authContextValues = {
        user,
        token,
        login,
        logout,
        status,
        setStatus,
        setUser
    };

    return (
        <>
            <AuthContext.Provider value={authContextValues}>
                {children}
            </AuthContext.Provider>
        </>
    );
}

export { AuthContext, AuthContextProvider }

import React from 'react'

export default function HomeSection({onClick,isLoading}) {
  return (
    <section
    id="home"
    class="bg-brand h flex justify-center items-center w-full text-white py-16 sm:py-24"
  >
    <div class="container mx-auto px-4 text-center">
      <h2 class="text-4xl sm:text-5xl font-bold mb-6 leading-tight">
        Empower Your Student Journey
      </h2>
      <p class="text-lg sm:text-xl mb-10 max-w-2xl mx-auto">
        Exclusive discounts, opportunities, and a thriving community
        for Ethiopian university students.
      </p>
      <a
        href="/register"
        onClick={onClick}
        class="bg-white w-32 text-brand px-8 py-3 rounded-full font-semibold hover:bg-opacity-90 transition duration-300 inline-block"
      >Join Now
        {/* {isLoading ? (
          <CircularProgress thickness={0.5} size={20} />
        ) : (
          "Join Now"
        )} */}
      </a>
    </div>
  </section>
  )
}

import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { iconSize, inputFontSize, inputHeight } from "../../constants/Constant";

export default function ChangePasswordForm({
  register,
  handleSubmit,
  onChangePassword,
  status,
  formState: { errors },
}) {
  return (
    <form onSubmit={handleSubmit(onChangePassword)}>
      <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box
          width={{ xs: "92%", sm: "100%" }}
          bgcolor="white"
          color="#1c2145"
          borderRadius={2}
        >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
            <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>Change Your Password</Typography>
          
              <FormControl fullWidth >
                <TextField
                  label="New Password"
                  variant="outlined"
                  placeholder="Enter Your Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <HttpsOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("password", { required: "Password Required" })}
                  {...(errors.password
                    ? { error: true, helperText: errors.password.message }
                    : {})}
                  type="password"
                  sx={{ fontSize: inputFontSize,height:inputHeight }}
                />
              </FormControl>
              <FormControl fullWidth >
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  placeholder="Confirm Your Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <HttpsOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("confirm_password", { required: "Confirm Password Required" })}
                  {...(errors.confirm_password
                    ? { error: true, helperText: errors.confirm_password.message }
                    : {})}
                  type="password"
                  sx={{ fontSize: inputFontSize,height:inputHeight }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#0ac20d",
                  ":hover": { backgroundColor: "#0ac20d" },
                }}
              >
                {status.loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "Change Password"
                )}
              </Button>
              
            </Stack>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

const { createTheme } = require("@mui/material");


const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
          },
        },
      },
      
    },
    typography: {
      fontFamily: [
        'Palanquin', 
  
      ],
    }
  });

export {theme};
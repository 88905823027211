import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext';

export default function LogoutButton() {
  const {logout}=useContext(AuthContext);

 
  return (
    <>
<Button variant='text' onClick={logout} sx={{position:"absolute",top:"3px",right:"10px"}}>Logout</Button>
    </>
  )
}

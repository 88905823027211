import React, { useContext, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { useForm } from "react-hook-form";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";
import { Box, Stack } from "@mui/material";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import VerifyOTPForm from "../../components/Verification/VerifyOTPForm";
import ChangePasswordForm from "../../components/auth/ChangePasswordForm";
import { AuthContext } from "../../contexts/AuthContext";
import AuthHeader from "../../components/auth/AuthHeader";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [status, setStatus] = useState({
    error: null,
    loading: false,
    sending: false,
  });
  const { login } = useContext(AuthContext);

  const [phone, setPhone] = useState();
  const [step, setStep] = useState(1);

  const onVerifyOTP = async (data) => {
    const url = baseUrl + "student/verify-otp";
    setStatus({ ...status, loading: true });
    try {
      const response = await axios.post(url, data);

      if (response.data.status === true) {
        setStep(3);
        setStatus({ ...status, loading: false, error: null });
      }
    } catch (error) {
      const msg = error.response?.data.message;
      if (msg) {
        setError("otp", {
          type: "custom",
          message: msg,
        });
      }

      setStatus({
        ...status,
        loading: false,
      });
    }
  };
  const onChangePassword = async (data) => {
    if(data.password!==data.confirm_password){
      setError('password',{
        type:"custom",
        message:"you need to confirm a new password"
      });
      return ;

    }
 
    const url = baseUrl + "student/change-password";
    setStatus({ ...status, loading: true });
    try {
      const response = await axios.post(url, data);

      if (response.data.status === true) {
        const userInfo = response.data.userInfo;
        const token = response.data.token;
        login({ userInfo, token });
      }
    } catch (error) {
      const msg = error.response?.data.message;
      if (msg) {
        setError("otp", {
          type: "custom",
          message: msg,
        });
      }

      setStatus({
        ...status,
        loading: false,
      });
    }
  };

  const onResendOTP = () => {
    onSendOTP({ phone: phone });
  };

  const onSendOTP = async (data) => {
    setPhone(data.phone);
    const url = baseUrl + "student/send-otp";
    setStatus({ ...status, sending: true });

    try {
      const response = await axios.post(url, data);
      if (response.data.status === true) {
        console.log(response.data.otp);
        if (step === 1) {
          setStep(2);
        }
      }
    } catch (error) {
      const msg = error.response?.data.message.phone;
      if (msg) {
        setError("phone", {
          type: "custom",
          message: msg,
        });
      }
    }
    setStatus({ ...status, sending: false });

  };

  var form = (
    <ForgotPasswordForm
      register={register}
      handleSubmit={handleSubmit}
      onSendOTP={onSendOTP}
      formState={{ errors }}
      status={status}
    />
  );

  if (step === 2) {
    form = (
      <VerifyOTPForm
        register={register}
        handleSubmit={handleSubmit}
        onVerifyOTP={onVerifyOTP}
        status={status}
        onResendOTP={onResendOTP}
        formstate={{ errors }}
        setStep={setStep}
      />
    );
  } else if (step === 3) {
    form = (
      <ChangePasswordForm
        register={register}
        handleSubmit={handleSubmit}
        status={status}
        onChangePassword={onChangePassword}
        formState={{ errors }}
      />
    );
  }
  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
          
          <AuthHeader/>
         
          
            {form}
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}

import React from "react";
import {
  Box,
  Stack,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  CircularProgress,
  Skeleton,
  FormControl,
  Link,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  ArrowRightAlt,
  KeyboardReturnOutlined,
  MailLockOutlined,
  PersonOutlineOutlined,
  School,
  Wc,
} from "@mui/icons-material";
import { iconSize, inputFontSize, inputHeight } from "../../constants/Constant";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

export default function StudentInfoForm({
  register,
  handleSubmit,
  onSubmit,
  formState: { errors },
  status,
  control,
  date,
  setDate,
  departments,
  universities,
  setValue,
  trigger,
  type,
  setStep
}) {


  const handleDateChange = (value, key) => {
    const formattedDate = dayjs(value.$d).format('YYYY-MM-DD');
    setDate({
      ...date,
      [key]: formattedDate
    });
  };

  const handleStepChange=()=>{
    setStep(1)
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", justifyContent: "center"  }}>
      <Stack
            width={{ xs: "92%", sm: "100%" }}
            bgcolor="white"
            color="#0f0e58"
            borderRadius={2}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            height="auto"

            my={"auto"}
            py={{ xs: 4, sm: 6 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              {
                type===2 &&
                <FormControl fullWidth >
 
                <TextField
                label="School Email"
                variant="outlined"
                {...(errors.school_email || status.error ? { error: true } : {})}
                helperText={ errors.school_email?(errors.school_email.message):(status.error)}   
                             placeholder="Enter Your School Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mx: 1 }}>
                      <MailLockOutlined sx={{ fontSize: iconSize }} />
                    </InputAdornment>
                  ),
                }}
                {...register("school_email", { required: "School Email Required",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@.+\.edu\.et$/,
                  message: "Invalid School Email",
                }, })}
                type="email"
                sx={{ fontSize: inputFontSize,height:inputHeight }}
              />
              </FormControl>

              }

              <TextField
                label="ID Number"
                variant="outlined"
                placeholder="Enter Your ID Number"
                {...(errors.id_no ? { error: true } : {})}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mx: 1 }}>
                      <PersonOutlineOutlined sx={{ fontSize: iconSize }} />
                    </InputAdornment>
                  ),
                }}
                {...register("id_no", { required: "ID No Required" })}
                helperText={ errors.id_no?errors.id_no.message:null}   

                type="text"
                sx={{ fontSize: inputFontSize,height:inputHeight }}
              />
            <Stack direction={"row"} spacing={{xs:1,sm:2}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Issued at"
                    name="issued_at"
                    onChange={(value)=>handleDateChange(value,'issued_at')}
                    
                  />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} >

                  <DatePicker
                    label="Expired at"
                    name="expired_at"
                    onChange={(value)=>handleDateChange(value,'expired_at')}

                  />
                </LocalizationProvider>
            </Stack>
              
             


              {universities.length > 0 ? (
                <Controller
                  name="university_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: "University Required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        field.onChange(selectedValue); // Update the field value
                        setValue("university_id", selectedValue); // Update the form value
                        trigger("university_id"); // Trigger validation
                      }}

                      error={Boolean(errors.university_id)}
                      helperText={ errors.university_id?(errors.university_id.message):(status.error)}   
                      sx={{ fontSize: inputFontSize,height:inputHeight }}

                      select
                      label="University"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ mx: 1 }}>
                            <School sx={{ fontSize: iconSize }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="">Select University</MenuItem>
                      {universities.map((university) => (
                        <MenuItem key={university.id} value={university.id}>
                          {university.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ) : (
                <>
                  <Skeleton variant="rectangular" height={52} />
                </>
              )}
              {departments.length > 0 ? (
                <Controller
                  name="study_field"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Study Field Required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        field.onChange(selectedValue); // Update the field value
                        setValue("study_field", selectedValue); // Update the form value
                        trigger("study_field"); // Trigger validation
                      }}
                      select
                      sx={{ fontSize: inputFontSize,height:inputHeight }}

                      label="Field of study"
                      variant="outlined"
                      {...(errors.study_field ? { error: true } : {})}
                      helperText={ errors.study_field?(errors.study_field.message):(status.error)}   
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ mx: 1 }}>
                            <Wc sx={{ fontSize: iconSize }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="">Select Field</MenuItem>
                      {departments.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ) : (
                <>
                  <Skeleton variant="rectangular" height={52} />
                </>
              )}
               
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#0f0e58",
                  ":hover": { backgroundColor: "#0f0e78" },
                }}
                disabled={status.loading }
              >
                {status.loading ? (
                  <CircularProgress sx={{color:"#0f0e58"}} size={25} />
                ): (
                  <>
                    Next <ArrowRightAlt />
                  </>
                )}
              </Button>
            </Stack>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Link
             onClick={handleStepChange}
              style={{
                textDecoration: "none",
                pointerEvents: status.loading ? "none" : "auto",
                color: "#0f0e58",
                fontFamily: "Palanquin",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor:"pointer",
                paddingBottom:"10px"

              }}
            >
              <KeyboardReturnOutlined />
              Return
            </Link>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

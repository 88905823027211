import React from "react";
import {
  Box,
  Typography,
  Stack
} from "@mui/material";
import {  TimelapseOutlined } from "@mui/icons-material";

export default function UnderReviewForm() {
  return (
       <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box width={{ xs: "92%", sm: "100%" }} bgcolor="white" color="#1c2145"  borderRadius={2}>
          <Box width={{ xs: "90%", sm: "80%" }} mx={"auto"} py={{xs:4,sm:8}} px={{xs:2,sm:2}}>
          <Stack spacing={4}>
            <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>
              Under Review
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TimelapseOutlined sx={{ fontSize: 100,color:"#12c63f" }} />
            </Box>

            <Typography align="center" variant="h5">
              Thanks for Submitting the <br /> required informations,
              <br /> Please wait till our team review <br /> and verify your
              information.
            </Typography>
          </Stack>
        </Box>
        </Box>
      </Box>
  );
}

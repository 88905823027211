import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { iconSize, inputFontSize } from "../../constants/Constant";
import { KeyboardReturnOutlined } from "@mui/icons-material";

export default function ForgotPasswordForm({
  register,
  handleSubmit,
  onSendOTP,
  status,
  formState: { errors },
}) {
  return (
    <form onSubmit={handleSubmit(onSendOTP)}>
      <Typography align="center" variant="subtitle1">
        Forgot Your Password
      </Typography>
      <Box width={{ display: "flex", justifyContent: "center" }}>
      <Stack
            width={{ xs: "92%", sm: "100%" }}
            bgcolor="white"
            color="#0f0e58"
            borderRadius={2}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              {/* <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>Reset Your Password</Typography> */}

              <FormControl fullWidth>
                <TextField
                  label="Phone"
                  variant="outlined"
                  placeholder="Enter Your Phone "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <LocalPhoneOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("phone", { required: "Phone Required", pattern: {
                    value: /^(09|07)\d{8}$/, // Regex pattern for starting with 09 or 07 followed by 8 digits
                    message: "Invalid Phone Number",
                  }, })}
                  {...(errors.phone ? { error: true } : {})}
                  helperText={errors.phone ? errors.phone.message : null}
                  type="tel"
                  sx={{ fontSize: inputFontSize }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#0f0e58",
                  ":hover": { backgroundColor: "#0f0e78" },
                }}
                disabled={status.sending}
              >
                {status.sending ? (
                  <CircularProgress sx={{ color: "#0f0e58" }} size={25} />
                ) : (
                  "Send OTP"
                )}
              </Button>
              
            </Stack>
          </Box>
           <Box sx={{ textAlign: "center" }}>

              <Link
              
                to="/login"
                style={{
                  textDecoration: "none",
                  pointerEvents: status.loading ? "none" : "auto",
                  color: "#0f0e58",
                  fontFamily: "Palanquin",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  paddingBottom:"10px"
                }}
              >
                <KeyboardReturnOutlined />
                Return      
               </Link>
              </Box>
        </Stack>
      </Box>
    </form>
  );
}

import React, { useContext, useReducer } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import RegisterForm from "../../components/auth/RegisterForm";
import { useForm } from "react-hook-form";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import AuthHeader from "../../components/auth/AuthHeader";

const initialState = {
  loading: false,
  serverErrors: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...state, loading: true, serverErrors: null };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        serverErrors: null,
      };
    case "ERROR":
      return { ...state, loading: false, serverErrors: action.errors };
    default:
      return state;
  }
};
export default function Register() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { login } = useContext(AuthContext);
  const onSubmit = async (inputData) => {
    const url = baseUrl + "student/register";
    dispatch({ type: "START" });
    try {
      const response = await axios.post(url, inputData);

      if (response.data.status === true) {
        dispatch({ type: "SUCCESS" });
        const userInfo = response.data.userInfo;
        const token = response.data.token;
        login({ userInfo, token });
      } 
    } catch (error) {
      const msg=error.response?.data.message;
      if(msg?.phone ){
        setError('phone',{
          type: "manual",
          message: error.response?.data.message.phone,
        });
      }
      if(msg?.password ){

        setError('password',{
          type: "manual",
          message: error.response?.data.message.password,
        });
      }
     dispatch({type:"SUCCESS"});
    }
  };

  return (
    <>
      {state.error ? <Alert title="Error" content={state.error} /> : null}
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
          <AuthHeader/>


            <Typography align="center" variant="subtitle1">
              Create an Account
            </Typography>

            <RegisterForm
              register={register}
              control={control}
              handleSubmit={handleSubmit}
              formState={{ errors }}
              onSubmit={onSubmit}
              status={state}
              trigger={trigger}
              setValue={setValue}
            />
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}

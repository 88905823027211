import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AccountBalance,
  CalendarMonth,
  FileCopyOutlined,
  Person,
  School,
  VerifiedUser,
} from "@mui/icons-material";
import { AuthContext } from "../../contexts/AuthContext";
const TextWithIcon = ({ icon, text }) => {
  return (
    <Box display="flex" alignItems="center">
      <Icon>{icon}</Icon>
      <Typography variant="body1" sx={{ ml: "3px" }}>
        {text}
      </Typography>
    </Box>
  );
};

const StackedRow = ({ label, text }) => {
  return (
    <Stack
      justifyContent={"space-between"}
      direction={"row"}
      sx={{
        borderBottom: "0.1px solid",
        borderColor: "#ebedf2",
        py: {xs:"15px",sm:"20px"},
        alignItems: "center",
      }}
    >
      {label}

      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
};
export default function ProfileForm() {
  const { user } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(user.student_et_id);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Box width={{ display: "flex", justifyContent: "center" }}>
      <Box
        width={{ xs: "92%", sm: "100%" }}
        bgcolor="white"
        color="#1c2145"
        borderRadius={2}
      >
        <Box
          width={{ xs: "90%", sm: "80%" }}
          mx={"auto"}
          py={{ xs: 2, sm: 4 }}
          px={{ xs: 2, sm: 2 }}
        >
          <Stack
            direction={"column"}
            width={"100%"}
            justifyContent={"center"}
          >
            <Stack
              alignSelf={"center"}
              direction={"row"}
              spacing={"20px"}
              justifyContent={"start"}
              sx={{
                backgroundColor: "#d5ffed",
                color: "#2fd952",
                width: "50%",
                py: "10px",
                pl: "20px",

                borderRadius: "25px",
              }}
            >
              <Icon >
                <VerifiedUser style={{ml:"30px",fontSize:20}} />
              </Icon>
              <Typography sx={{ fontWeight: "bold" }}>Verified.</Typography>
            </Stack>

            <StackedRow
              label={<TextWithIcon icon={<Person />} text={"Full Name"} />}
              text={user.name}
            />

            <StackedRow
              label={
                <TextWithIcon
                  icon={<AccountBalance />}
                  text={"University Name"}
                />
              }
              text={user.university}
            />

            <StackedRow
              label={<TextWithIcon icon={<School />} text={"Field"} />}
              text={user.field}
            />

            <StackedRow
              label={
                <TextWithIcon icon={<CalendarMonth />} text={"Expired at"} />
              }
              text={user.expired_at}
            />

            <Stack
              alignSelf={"center"}
              direction={"column"}
              spacing={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "50%",
                py: "10px",
                px: "5px",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Student.Et ID Number
              </Typography>
              <Stack
                direction={"row"}
                spacing={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  borderRadius: "25px",
                  backgroundColor: "#ebedf2",
                  py: "3px",
                  px: "20px",
                }}
              >
                <Typography variant="p" sx={{ fontSize: 18,color:"#1b1989" ,   fontFamily: "Palanquin",
}}>
                  {user.student_et_id}
                </Typography>
                <Tooltip  title={copied ? "Copied" : "Copy"}>
                  <IconButton onClick={handleCopy}>
                    <FileCopyOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

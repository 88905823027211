import React from 'react'

export default function AboutSection() {
  return (
    <section
    id="about"
    class="h flex justify-center items-center py-16 w-full sm:py-20  bg-gray-100"
  >
    <div class="container mx-auto px-4">
      <h3 class="text-3xl font-semibold text-center  sm:mb-16 text-brand">
        About Us
      </h3>
      <div class="container mx-auto text-center py-3 mb-12 sm:w-[80%]">
        Student.et is Ethiopia's premier student verification
        platform, designed to help businesses offer exclusive
        discounts to eligible students. Our platform enables students
        to verify their credentials using their school ID or school
        email, ensuring that only genuine students can access the
        benefits you provide.
      </div>
      <div class="grid sm:grid-cols-3 gap-8 sm:gap-16">
        <div class="bg-white p-4 sm:p-6 rounded-2xl">
          <h4 class="text-2xl font-semibold mb-6 text-brand">
            Mission
          </h4>
          <p class="mb-8 text-gray-600 leading-relaxed">
            Our mission is to empower businesses to offer authentic
            student discounts by providing a reliable platform for
            student verification. We ensure that every student who
            receives a discount is verified through a secure process,
            using their school ID or school email.
          </p>
        </div>
        <div class="bg-white p-4 sm:p-6 rounded-2xl">
          <h4 class="text-2xl font-semibold mb-6 text-brand">
            Vision
          </h4>
          <p class="mb-8 text-gray-600 leading-relaxed">
            Our vision is to become the trusted partner for businesses
            across Ethiopia in offering student discounts. We aim to
            create a seamless verification experience that enhances
            trust and fosters stronger relationships between
            businesses and the student community.
          </p>
        </div>
        <div class="bg-white p-4 sm:p-6 rounded-2xl">
          <h4 class="text-2xl font-semibold mb-6 text-brand">
            Value
          </h4>
          <p class="mb-8 text-gray-600 leading-relaxed">
            <div class="flex flex-col space-y-2">
              <div class="inline-block space-x-1">
                <span class="font-bold">Security :</span>
                <span>
                  Our platform is built with top-tier security
                  measures to protect student data and ensure a safe
                  verification process.
                </span>
              </div>

              <div class="inline-block space-x-2">
                <span class="font-bold">Ease of Use :</span>
                <span>
                  We provide an easy and efficient verification
                  process, allowing students to verify their status
                  quickly and businesses to offer discounts
                  confidently.
                </span>
              </div>

              <div class="inline-block space-x-2">
                <span class="font-bold">Partnership :</span>
                <span>
                  We work closely with educational institutions and
                  businesses to ensure a smooth and reliable
                  verification process.
                </span>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}
